import { HttpClient } from 'typed-rest-client/HttpClient';
import { IHttpClientResponse } from 'typed-rest-client/Interfaces';

interface CalculatorRequest {
    x: number,
    y: number,
    operator: string
}

export interface CalculatorResponse {
    x: number,
    y: number,
    result: number,
    operator: string
}

const USER_AGENT = "RestClient";
const URL = "https://beta.calculator.ltqdev.com/calculator"

class CalculatorApiClient {

    client: HttpClient

    constructor() {
        this.client = new HttpClient(USER_AGENT);
    }

    public async callApi(x:number, y:number, operator:string): Promise<CalculatorResponse> {

        let requestBody: CalculatorRequest = {
            x: x,
            y: y,
            operator: operator
        }

        let response: IHttpClientResponse =
            await this.client.post(URL, JSON.stringify(requestBody), {
                "Content-Type": "application/json"
            });

        let responseBody: CalculatorResponse = JSON.parse(await response.readBody())
        return responseBody;
    }
}

export default CalculatorApiClient;
