import { Component } from 'react';
import calculatorApiClient from './CalculatorApiClient';
import CalculatorApiClient from './CalculatorApiClient';
import { Button, Header, Input } from 'semantic-ui-react';

type CalculatorComponentProps = {
};

type CalculatorComponentState = {
    x: number,
    y: number,
    result: number,
    operator: string
}

class CalculatorComponent extends Component<CalculatorComponentProps, CalculatorComponentState> {

    calculatorClient: calculatorApiClient = new CalculatorApiClient();


    constructor(props: CalculatorComponentProps) {
        super(props);
        // initialize state
        this.state = {
            x: 0,
            y: 0,
            result:0,
            operator: "Add"
        };
    }

    submitOnClick = () => {
        console.log("Button clicked...")
        this.calculatorClient.callApi(this.state.x, this.state.y, this.state.operator)
            .then((response) => this.setState({
                x: response.x,
                y: response.y,
                operator: response.operator,
                result: response.result
            }));
    }

    render() {
        return (
            <div>
                <Header as='h1'> Calculator </Header>
                <Input name="x" placeholder="x">
                    <input onChange={event =>
                        this.setState({x: +event.target.value})}/>
                </Input>
                <Input focus placeholder="y">
                    <input onChange={event =>
                        this.setState({y: +event.target.value})}/>
                </Input>
                <Input focus placeholder="operator">
                    <input onChange={event =>
                        this.setState({operator: event.target.value})}/>
                </Input>
                <Button onClick={this.submitOnClick}>Calculate</Button>
                <Header as='h4'> Result: {this.state.result}</Header>
            </div>
        )
    }

}

export default CalculatorComponent;